<template>
  <div class="auth-wrapper auth-v1 px-2">
    <b-overlay :show="loading" rounded="sm">
      <div class="auth-inner py-2">
        <!-- Login v1 -->
        <b-card class="mb-0 p-2 m-0" style="max-width: 100rem">
          <b-link class="brand-logo">


            <h2 class="brand-text text-primary ml-1">eWork</h2>
          </b-link>

          <b-card-title class="mb-1 text-center">
            Vérification d'adresse mail
          </b-card-title>
          <b-card-text class="mb-2">
            Un code de vérification a été envoyé à l'adresse :
            <br />
            <span>
              <strong>{{ customerInfo.email }}</strong>
            </span>
          </b-card-text>

          <!-- form -->
          <validation-observer ref="codeSend" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="validateCode">

              <b-form-group>
                <ky-input v-model="data.code" :placeholder="'Code de vérification'" :rules="'required'">
                </ky-input>
                <b-card-text class="mt-1">
                  <b-link :to="{ name: 'login-return-code' }">
                    <feather-icon icon="ChevronsRightIcon"></feather-icon>
                    Je n'ai pas reçu le code
                  </b-link>
                </b-card-text>
              </b-form-group>
              <!-- submit button -->
              <b-button variant="primary" :disabled="invalid" @click="validateForm">
                Valider
              </b-button>
            </b-form>
          </validation-observer>

          <!-- social button -->
        </b-card>
        <!-- /Login v1 -->
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import kyInput from "@/@core/layouts/components/ky-input.vue";

import { required } from "@validations";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    kyInput,
    ToastificationContent,
  },
  data() {
    return {
      data: {
        code: ""
      },
      required,
      code: "",
      errorMessage: "",
      error: "",
      loading: false,
      errors: "",
    };
  },

  computed: {
    ...mapState('customer', ['customerInfo'])
  },

  methods: {

    ...mapActions("customer", ["validateCode"]),

    async validateForm() {
      const isValid = await this.$refs.codeSend.validate();
      if (!isValid) return;
      this.loading = true;
      this.validateCode(this.data)
        .then((res) => {
          this.loading = false;
          this.$router.replace("/customer-password");
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = error.message;
          this.errors = error.response.data;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.errors?.message,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });

    },


  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
